import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/SweetSampler/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    effectClick: 'effect-click.mp3',
    effectCandyClick: 'effect-candy-click.mp3',
    effectIncorrect: 'incorrect.mp3',
    effectCorrect: 'correct.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    candy: 'candy.webp',
    corn: 'corn.webp',
    fish: 'fish.webp',
    bear: 'bear.webp',
    heart: 'heart.webp',
    bean: 'bean.webp',
    kiss: 'kiss.webp',
    life_saver: 'life_saver.webp',
    peep: 'peep.webp',
    star: 'star.webp',
    modal: 'modal.webp',

    candy_gray: 'candy-gray.webp',
    corn_gray: 'corn-gray.webp',
    fish_gray: 'fish-gray.webp',
    bear_gray: 'bear-gray.webp',
    heart_gray: 'heart-gray.webp',
    bean_gray: 'bean-gray.webp',
    kiss_gray: 'kiss-gray.webp',
    life_saver_gray: 'life_saver-gray.webp',
    peep_gray: 'peep-gray.webp',
    star_gray: 'star-gray.webp'

  }
};

export default generateAssets(assetsDir, gameAssets);