//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';

export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      container: null,
      answerModal: null,
      answerSubmitted: false,
      answerSubmittedResponse: null,
      revealAnswers: false,
      revealedAnswers: [],
      correctThisRound: 0,
      candiesPerTrial: 1,
      incorrectAnswers: false,
      nextTrial: false,
      playDisabled: false,
      actionInProgress: false,
      playStart: null,
      totalAnswers: 0,
      endRoundText: '',
      wrongOrder: false,
      questionKey: '',
      answerKey: 'asdasasd',
      isAnswerSubmittedCorrect: null,
      unusedSizes: [],
      answerColors: {
        correct: 'green',
        incorrect: 'red'
      },
      totalQueryWords: 0,
      colorThisTrial: null,
      totalAnswersPerTrial: 1,
      candyTrayKey: Date.now() + Math.floor(Math.random() * 100000),
      questionProgress: 0.0,
      questionIndex: null,
      wasCandyClicked: false,
      amountOfSizes: 1,
      lastSize: null,
      amountOfColors: 1,
      totalSizes: [],
      defaultSize: null,
      totalColors: [],
      totalTypes: [],
      paddle: null,
      //game data
      scoring: {
        clicked_candy: 30,
        missed_candy: 15
      },
      timer_bonus: 0,
      buttonDisabled: false,
      questionCount: 2,
      mode: 'question',
      errorLabel: {
        color: 'Now enter all the candies by color in the order presented.',
        size: 'Now enter the candies in order: all the smalls, then all the mediums, then all the larges.',
        sizeError: 'Watch the order and size of the sweets!',
        success: 'You got the order correct!'
      },
      errorMode: 'size',
      showTaskHint: false,
      expectedAnswers: [],
      trialQuestions: [],
      answerColor: 'grey',
      userAnswers: [],
      gameModes: ["size", "color"],
      sizes: [1, 2, 3],

      candy_types: ["candy", "corn", "fish", "bear", "heart", "bean", "kiss", "life_saver", "peep", "star"],
      candy_color: ["blue", "green", "grey", "orange", "purple", "red", "yellow"],
      availableCandyTypes: ["candy", "corn", "fish", "bear", "heart", "bean", "kiss", "life_saver", "peep", "star"],

      // UI
      refresh: 0,
      currentQuestionIndex: 1,
      randomNumbers: {
        sizes: null
      }
    };
  },
  methods: {
    toggleMode() {
      this.setGameMode(this.gameMode === 'size' ? 'color' : 'size');
      this.setTextPlayHints(this.gameMode);
    },
    /**
     * Initialize the game play
     * @returns {void}
     */
    async initializeGamePlay(isFirstInit = true) {
      this.totalSizes = [];
      this.totalTypes = [];
      this.totalColors = [];

      this.questionKey = this.createUniqueKey();
      this.answerKey = this.createUniqueKey();

      this.setGameExited(false);
      this.setHints([]);
      this.setTimer('false');
      if (isFirstInit) {
        this.setScore(0);
        this.container = this.$refs.gameplay;
        this.answerModal = this.$refs.answerModalRef;
        this.playDisabled = true;
        this.incorrectAnswers = false;

        this.setTextPlayHints(this.gameMode);
      }

      this.wasCandyClicked = false;
      this.currentQuestionIndex = 1;
      this.setQuestionMode();

      // reveal selected buttons for this session
      console.log('initializing gameplay');

      await this.getSettings(isFirstInit); // this will actually get the user, grid data
      await this.hideAllCandiesAnimation(); // hide all candies

      await this.sleep(100); // wait
      this.shuffleCandies();
      await this.generateGameData();
      this.playStart = new Date().getTime(); // set the play start time

      await this.startGamePlayMode();

      await this.sleep(9999999); // wait
      console.log('got to end of initializeGamePlay');
    },
    /**
     * Get the game settings based on user level and game id
     * @param isFirstPlay
     * @returns {Promise<unknown>}
     */
    async getSettings(isFirstPlay = false) {

        let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });
        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);
        this.setRounds(6);
        this.candiesPerTrial = this.getRandomIntegerInRange(
            this.gameLevelData.game_settings.candies_per_trial_min,
            this.gameLevelData.game_settings.candies_per_trial_max
        );

        this.amountOfSizes = this.getRandomIntegerInRange(
            this.gameLevelData.game_settings.min_size,
            this.gameLevelData.game_settings.max_size
        );

        this.amountOfColors = this.getRandomIntegerInRange(
            this.gameLevelData.game_settings.min_color,
            this.gameLevelData.game_settings.max_color
        );

        if (isFirstPlay) {
          this.setLevel(this.gameLevelData.start_level);
          this.setTimerStopAfter(this.gameLevelData.game_settings.timer_duration_2 * 10);
        }
    },
    /**
     * Starts the main game play loop
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      this.setMusic(this.assets.audio.gamePlay, true);
      this.playDisabled = false;
      this.actionInProgress = false;

      this.modalMessageReset();
      if (this.isFirstPlay()) {
        this.showTaskHint = true;
        await this.sleep(2000); // wait
        this.showTaskHint = false;
      }

      this.buttonDisabled = false;
      this.userAnswers = [];
      this.answerColor = 'grey';

      if (!this.isFirstPlay()) {
        this.mode = '';
        await this.sleep(500);
      }

      this.setMusic(this.assets.audio.gamePlay);
      await this.getPlayMode();
    },
    /**
     * Generates the game play data for the current round and current game play mode
     * @returns {Promise<void>}
     */
    async generateGameData() {
      // Initialize game variables.
      this.expectedAnswers = [];
      this.currentQuestionIndex = 1;

      // Determine game mode.
      this.errorMode = this.gameMode;
      // if gameMode === size, set this.colorThisTrial to a random color
      if (this.gameMode === 'size') {
        this.colorThisTrial = this.candy_color[this.getRandomIntegerInRange(0, 6)];
      }
      console.log('candies per trial', this.candiesPerTrial);
      if (this.amountOfSizes === 1) {
        this.defaultSize = this.sizes[this.getRandomIntegerInRange(0, this.sizes.length - 1)]; // or whichever size you want to be the default
      } else {
        // Reset default size if the amount of sizes changes
        this.defaultSize = null;
        this.initializeUnusedSizes();
      }

      // Generate trial tasks and expected answers.
      const trialQuestions = Array.from({length: this.candiesPerTrial}, this.generateTrialTask);
      const expectedAnswers = this.sortAnswers([...trialQuestions]);

      let hints = this.gameMode === 'color' ? expectedAnswers.map(obj => obj.color) : [];
      if (this.gameMode === 'color' && this.amountOfColors === 1) {
        // reduce hints array to just the first item in the array if this is just one color
        hints = hints.slice(0, 1);
      }

      // hints should be unique colors
      hints = [...new Set(hints)];
      this.setHints(hints);

      // Use Vue's reactivity system to update the trial questions and expected answers.
      this.$set(this, 'trialQuestions', trialQuestions);
      this.$set(this, 'expectedAnswers', expectedAnswers);

      console.log('◭ generateGameData --- expectedAnswers', expectedAnswers);
    },
    /**
     * Generates a trial task
     * @returns {{size: number, type: string, color: string, colorOrder: number, index: number}}
     */
     generateTrialTask() {
    const size = this.getSizeForTrial();
    const type = this.getTypeForTrial();
    const color = this.getColorForTrial();

    const colorOrder = this.getRandomIntegerInRange(0, this.candiesPerTrial);
    const index = this.candy_types.indexOf(type);

    return {size, type, color, colorOrder, index};
  },

  getSizeForTrial() {
    let size;

    if (this.gameMode === 'size') {
      size = this.amountOfSizes === 1 ? this.defaultSize : this.getNewSize();
    } else {
      size = this.sizes[2];
    }

    return size;
  },

  getNewSize() {
    let newSize;
    do {
      newSize = this.sizes[this.getRandomIntegerInRange(0, this.sizes.length - 1)];
    } while (this.amountOfSizes > 1 && newSize === this.lastSize);

    this.totalSizes.push(newSize);
    this.lastSize = newSize;

    return newSize;
  },

  getTypeForTrial() {
    let randomIndex = Math.floor(Math.random() * this.availableCandyTypes.length);
    let type;

    if (this.totalTypes.length < this.candiesPerTrial) {
      type = this.availableCandyTypes[randomIndex];
      this.totalTypes.push(type);
    } else {
      type = this.totalTypes[this.getRandomIntegerInRange(0, this.totalTypes.length - 1)];
    }

    this.availableCandyTypes.splice(randomIndex, 1);

    return type;
  },

  getColorForTrial() {
    let color;

    if (this.totalColors.length < this.amountOfColors) {
      color = this.getNewColor();
    } else {
      color = this.totalColors[this.getRandomIntegerInRange(0, this.totalColors.length - 1)];
    }

    return color;
  },

  getNewColor() {
    let color = this.gameMode === 'size' ? this.colorThisTrial : undefined;

    while (color === undefined || this.totalColors.includes(color)) {
      color = this.candy_color[this.getRandomIntegerInRange(0, 6)];
    }

    this.totalColors.push(color);

    return color;
  },
    /**
     * Sorts the answers depending on the game mode
     * @param answers
     * @returns {*}
     */
    sortAnswers(answers) {
      // if this is a color game, we don't need to sort the answers unless there's more than one color
      if (this.gameMode === 'color' && this.amountOfColors === 1) {
        return answers;
      }

      // Add an index property to each object
      answers.forEach((answer, index) => {
        answer.sortIndex = index;
      });

      const propertyToSort = this.gameMode === 'size' ? 'size' : 'colorOrder';
      return answers.sort((a, b) => {
        if (a[propertyToSort] === b[propertyToSort]) {
          if (a.sortIndex >= b.sortIndex) {
            return 1;
          } 
          
          return -1;
        } else {
          if (a[propertyToSort] >= b[propertyToSort]) {
            return 1;
          } 
          
          return -1;
        }
      });
    },
    /**
     * Shuffle the candies for randomization in presentation
     * @returns {Promise<void>}
     */
    shuffleCandies() {
      let type = this.shuffleArray(this.candy_types);
      let colors = this.shuffleArray(this.candy_color);
      this.candy_types = type;
      this.candy_color = colors;
      this.candyTrayKey = Date.now() + Math.floor(Math.random() * 100000);
    },
    /**
     * Starts the actual game play mode
     * @returns {Promise<void>}
     */
    async getPlayMode() {
      console.log('◭ getPlayMode --- next candy');
      if (this.candiesRemaining) {

        this.playDisabled = false;
        // continue candy hints
        this.setQuestionMode();
        await this.showCandyHint();
      }
    },
    /**
     * Shows a candy hint and animates the board. Initiates the timer and waits for the user to click, else marks as missed
     * @returns {Promise<boolean>}
     */
    async showCandyHint() {
      console.log('◭ showCandyHint --- next candy');
      this.wasCandyClicked = false;

      if (!this.playDisabled) {
        await this.hideAllCandiesAnimation();
        await this.showQuestionCandyAnimation();
        await this.animateBoardSwipeIn();

        await this.startTimer(this.getStimulusDisplayInterval(), true);
        await this.sleep(800); // stop for 800ms so it doesn't feel so rushed

        if (this.candiesRemaining) {
          this.stopAndHideTimer();
          await this.animateBoardSwipeDown();
          console.log('▛ showCandyHint --- wasCandyClicked', this.wasCandyClicked);
        }

        if (!this.wasCandyClicked) {
          console.log('▛ showCandyHint --- wasCandyClicked is false');

          this.decreaseScore(this.scoring.missed_candy);
          this.setEffect(this.assets.audio.effectIncorrect);
          this.playDisabled = true;
          
          if (this.candiesRemaining) {
            this.currentQuestionIndex++
          }

          console.log('▛ candiesRemaining', this.candiesRemaining);
          this.candiesRemaining ? await this.getPlayMode() : await this.setAnswerMode();
        }

        return true;
      } else {
        return false;
      }
    },
    /**
     * Handles the candy click event
     * @returns {Promise<void>}
     */
    async candyClicked(event, index) {
      if (this.playDisabled || this.questionIndex !== index) {
        console.log('play is disabled or question index is not the same as the index');
        return;
      }
      this.playDisabled = true;

      const candyElement = event.target;
      this.setEffect(this.assets.audio.effectCandyClick);
      this.wasCandyClicked = true;
      this.currentQuestionIndex++;
      
      this.increaseScore(this.scoring.clicked_candy);
      this.stopAndHideTimer();
      await this.spinAndFade(candyElement);
      await this.animateBoardSwipeRight();
      this.candiesRemaining ? await this.getPlayMode() : await this.setAnswerMode();
    },
    /**
     * Set the game mode to question mode
     * @returns {void}
     */
    setQuestionMode() {
      this.mode = 'question';
      this.questionKey = this.createUniqueKey();
    },
    /**
     * Set the game mode to answer mode
     * @returns {Promise<void>}
     */
    async setAnswerMode() {
      console.log('◭ setAnswerMode');
      this.mode = 'answer';
      this.answerKey = this.createUniqueKey();
      // animate in
      await this.sleep(1000);
      await this.animateBoardSwipeIn();
    },
    /**
     * Calculate the stimulus display interval based on the game level
     * @returns {number}
     */
    getStimulusDisplayInterval() {
      try {
        let interval = Number(this.gameLevelData.game_settings.display_interval_max);
        return interval * 5;
      } catch (e) {
        return 0;
      }
    },
    /**
     * Show the game play instructions
     * @param count
     * @returns {void}
     */
    async showGamePlayInstructions(count = 1) {
      // show hints dependent on Level
      this.hintMode = true;
      if (this.gameExited) {
        this.unloadMusic();
        return; // make sure if the user exits at this stage, we don't keep going
      }

      this.setModalMessage({
        title: gamePlayCopy.gamePlay.playTitle,
        body: this.formatText(this.gameMode === 'color' ? gamePlayCopy.gamePlay.playInstructions : gamePlayCopy.gamePlay.playInstructionsSize, {}),
        actionButton: {action: this.startGamePlayMode, text: gamePlayCopy.gamePlay.playButton}
      });

      this.hintMode = false;
      return true;
    },
    /**
     * Determine the next game phase
     * @returns {Promise<void>}
     */
    async determineNextGamePhase() {
      // save the trial results
      await this.setGameLevelAndSaveTrialResults();
      this.revealedAnswers = [];
      if (!this.gamePlayComplete) {
        console.log('gameplay not complete');
        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        console.log('gameplay is complete');
        await this.handleGamePlayComplete();
      }
    },
    /**
     * Handle the end of a round and go to the next round
     * @returns {Promise<void>}
     */
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    async goToNextTrial() {
      this.answerSubmitted = false;
      this.playDisabled = true;
      this.modalMessageReset();
      this.availableCandyTypes.splice(0, this.availableCandyTypes.length, ...this.candy_types);

      await this.sleep(2400); // so we see stuff before it goes away?
      this.playDisabled = false;
      // this.nextTrial = true
      this.stopAndHideTimer();
      this.startNewRound();
      if (this.currentTrial === 1) {
        this.resetTrialsCorrect();
        this.clearTrialStatus();
        this.correctThisRound = 0;
      }

      this.playStart = new Date().getTime(); // start counting the next trial time
      await this.initializeGamePlay(false);
    },
    /**
     * Calculate the score and level for the end of the round
     * @returns {Promise<void>}
     */
    async endRoundScore() {
      // all of the answers are correct

      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');
        this.goUpLevel();
        this.increaseTickets(3);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');

        if (this.level > 1) this.goDownLevel();
      }

      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
    },
    /**
     * Set trial to pre-0 and go to next round after showing the end of round modal. Show level up if applicable
     * @returns {Promise<void>}
     */
    async goToNextRound() {
      this.showEndOfRoundModal();

      this.incorrectAnswers = false;
    },
    /**
     * The game play is complete. Show the end of game modal and update the play level
     * @returns {Promise<void>}
     */
    async handleGamePlayComplete() {
      console.log('game play is complete');
      await this.endRoundScore();
      this.increaseTickets(6);
      this.setTicketEffect();
      // all of the answers are correct
      // game play is complete. confetti or whatever
      this.playDisabled = true;
      await this.updatePlayLevel({
        gameId: this.gameId,
        play_id: this.play_id,
        level: Math.max(1, this.level),
        tickets: this.tickets,
        stopTime: new Date().getTime()
      });

      this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
    },
    /**
     * Show the end of round modal
     * @returns {void}
     */
    showEndOfRoundModal() {
      console.table({
        allAnswersCorrect: this.allAnswersCorrect,
        someAnswersCorrect: this.someAnswersCorrect,
        noAnswersCorrect: this.noAnswersCorrect
      });
      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      } else if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      } else if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      // Set modal message for end of round
      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: this.correctThisRound}),
        actionButton: {
          action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
          text: gamePlayCopy.rounds.playNext
        }
      });

      this.expectedAnswersPerTrial = 0;

      this.clearCorrectAnswers();
      this.incorrectAnswers = false;
    },
    /**
     * Enter button click handler for answer modal
     * @returns {Promise<void>}
     */
    async enterClickHandler() {
      if (this.buttonDisabled) return;
      this.stopMusic();
      clearInterval(this.questionProgressTimer);
      this.buttonDisabled = true;
      this.answerSubmitted = true;

      let isCorrectAnswer = this.areAnswersCorrect();
      this.revealAnswers = true;
      this.isAnswerSubmittedCorrect = isCorrectAnswer;

      if (isCorrectAnswer) {
        this.setEffect(this.assets.audio.effectCorrect);
        this.increaseScore(this.scoring.clicked_candy);
        this.correctThisRound += 1;
        this.answerSubmittedResponse = gamePlayCopy.answerFeedback[this.gameMode].correct;
      } else {
        this.setEffect(this.assets.audio.effectIncorrect);
        this.decreaseScore(this.scoring.missed_candy);
        this.incorrectAnswers = true;
        this.answerSubmittedResponse = gamePlayCopy.answerFeedback[this.gameMode].incorrect;
      }

      for (const [index] of [...this.userAnswers].entries()) {
        console.time('∞∞ highlight');
        this.revealedAnswers.push(true);
        let answerElement = this.$refs.answer[index].$el;
        answerElement.classList.remove('bg-grey');
        answerElement.classList.add('bg-' + this.isAnswerCorrect(index));
        await this.sleep(500);

        console.timeEnd('∞∞ highlight');
      }


      await this.sleep(1000); // let them see the feedback first

      for (let i = 0; i < this.userAnswers.length; i++) {
        await this.setAnswerResponse(i);
        await this.sleep(500);
      }

      if (isCorrectAnswer) {
        this.increaseTrialsCorrect();
        this.trialWasCorrect = true;
        this.setTrialStatus('correct');
      } else {
        this.trialWasCorrect = false;
        this.setTrialStatus('incorrect');
      }

      await this.sleep(1000); // let them see the feedback first
      setTimeout(async () => {
        await this.animateBoardSwipeLeft();
        this.revealAnswers = false;
        await this.determineNextGamePhase();
        this.answerSubmitted = false;
        this.isAnswerSubmittedCorrect = null;
      }, 500);
    },
    areAnswersCorrect() {
      // reorder this.expectedAnswers so that the color properties are the same order as this.hints color strings. At the same time for each color group, make sure that the order is kept from the original expectedAnswers
      if (this.gameMode === 'color') {
        let expectedAnswers = this.expectedAnswers.slice();
        let hints = this.hints.slice();
        let expectedAnswersSorted = [];
        for (let hint of hints) {
          let colorGroup = expectedAnswers.filter(answer => answer.color === hint);
          expectedAnswersSorted.push(...colorGroup);
        }

        this.expectedAnswers = expectedAnswersSorted;
      }

      return this.expectedAnswers.length === this.userAnswers.length &&
          this.expectedAnswers.every((question, i) => {
            console.log("Comparing:", question.type.trim(), "with", this.userAnswers[i].trim());
            return question.type.trim() === this.userAnswers[i].trim();
          });
    },
    async setAnswerResponse(i) {
      if (this.expectedAnswers[i] !== undefined && this.userAnswers[i] !== this.expectedAnswers[i].type) {
        const answerElement = this.$refs['userAnswer' + i];
        await this.spinAndFade(answerElement);
        this.$set(this.userAnswers, i, this.expectedAnswers[i].type);
        let answerButton = this.$refs.answer[i].$el;
        answerButton.classList.remove('bg-grey');
        answerButton.classList.remove('bg-red');
        answerButton.classList.add('bg-green');
        await this.reverseSpinAndFade(answerElement);
        await this.sleep(500);
      } else if (this.expectedAnswers[i] === undefined) {
        const userAnswerWrapper = this.$refs['userAnswerWrapper' + i];
        await this.spinAndFade(userAnswerWrapper);
      }
    },
    /**
     * Backspace button click handler for answer modal
     * @returns {void}
     */
    async backspaceClickHandler() {
      if (this.buttonDisabled) return;
      if (this.userAnswers.length > 0) {
        this.userAnswers.pop();
      }
    },
    /**
     * User answer click handler for answer modal
     * @param event
     * @param index
     * @returns {Promise<void>}
     */
    async userAnswerClickHandler(event, index) {
      if (this.buttonDisabled) return;
      if (this.userAnswers.length < 8) {
        this.setEffect(this.assets.audio.effectClick);
        this.userAnswers.push(this.candy_types[index]);
      }
    },
    /**
     * Hides all candies on board
     * @returns {Promise<void>}
     */
    async hideAllCandiesAnimation() {
      for (const candy of this.$refs.candies) {
        candy.style.opacity = '0';
        // await this.animation.to(candy, 0.0, {opacity: 0, scaleX:1, scaleY:1, ease: "bounce"})
      }

      for (const candy of this.$refs.candies1) {
        candy.style.opacity = '0';
        // await this.animation.to(candy, 0.0, {opacity: 0, scaleX:1, scaleY:1, ease: "bounce"})
      }
    },
    /**
     * Shows all candies on board
     * @returns {Promise<void>}
     */
    async showQuestionCandyAnimation() {
      let question = this.trialQuestions[Math.max(0, this.currentQuestionIndex - 1)];
      this.imageSymbol = question.type;
      let index = question.index;
      this.questionIndex = index;

      // Function to generate the url for the image
      const imageUrl = (color, type) =>
          this.CDN + `games/SweetSampler/images/candies/${color}_${type}.webp`;

      // Simplified scale calculation - if gameMode is size, scale is 1.3, 0.4, 0.8 for small, medium, large, else if gameMode is color, scale is always 1.3
      let scale = this.gameMode === 'size' ? [1.3, 0.3, 0.6][question.size] || 1.3 : 1.3;

      // Define the candy either from the first or second set
      let candy = index < 5 ? this.$refs.candies[index] : this.$refs.candies1[index - 5];

      // Set the backgroundImage of the candy
      // Wrap this in an undefined check, sometimes if we quit the game before the animation is complete, the candy will be undefined
      if (candy !== undefined) {
        candy.style.backgroundImage = `url(${imageUrl(question.color, question.type)})`;

        // Animate the candy
        await this.animation.to(candy, 0.3, {
          rotate: 0,
          opacity: 1,
          scaleX: scale,
          scaleY: scale,
          ease: "bounce"
        });
      }

    },
    /**
     * Build answer keyboard first row
     * @returns {string[]}
     */
    firstSixCandyGray() {
      let candies = this.candy_types.slice();
      return candies.slice(0, 6);
    },
    /**
     * Build answer keyboard second row
     * @returns {string[]}
     */
    lastFourCandyGray() {
      let candies = this.candy_types.slice();
      candies.splice(0, 6);
      return candies;
    },
    /**
     * Get the color hex for the answer
     * @param answer
     * @returns {*}
     */
    hexForAnswerColor(answer) {
      const colorObject = this.colorValues.find(colorValue => colorValue.name === answer.color);
      return colorObject.hex;
    },
    /**
     * Get the appropriate background color for the answer feedback based on the answer
     * @param index
     * @returns {string}
     */
    isAnswerCorrect(index) {
      if (!this.expectedAnswers[index] || !this.userAnswers[index]) {
        // If userAnswers[index] is defined but expectedAnswers[index] is not, return 'red'. Otherwise, return 'grey'
        return this.userAnswers[index] ? 'red' : 'grey';
      }

      return this.expectedAnswers[index].type === this.userAnswers[index] ? 'green' : 'red';
    },
    initializeUnusedSizes() {
      this.unusedSizes = this.sizes.slice();
    }
  },
  computed: {
    candiesRemaining() {
      return this.currentQuestionIndex <= this.trialQuestions.length;
    },
    allAnswersCorrect() {
      return this.incorrectAnswers === false;
    },
    someAnswersCorrect() {
      return this.correctThisRound > 0 && this.incorrectAnswers === true;
    },
    noAnswersCorrect() {
      return this.correctThisRound < 1;
    },
    uniqueColorsFromExpectedAnswers() {
      return Object.values(
          this.expectedAnswers.reduce((acc, obj) => {
            acc[obj.color] = obj;
            return acc;
          }, {})
      );
    }
  },
  async mounted() {
    await this.initializeGamePlay(); // start round 1, trial 1
  },
  created() {
    this.randomNumbers.sizes = this.createUniqueRandomGenerator();
    this.randomNumbers.sizes.setBufferSize(3);
  }
};
