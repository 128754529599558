export default {
    gamePlay: {
        playTitle: "Save the Candy!",
        playInstructions: "<p>The candy sorter is trashing our sweets!</p><p>Click on each piece of candy to save it.</p><p>Candies will be different colors. Remember their COLOR</p><p>Then, SORT the candies by the Color Order at the bottom of your screen. All the first color in order, then all the second color in order, and so on.</p>",
        playInstructionsSize: "<p>The candy sorter is trashing our sweets!</p><p>Click on each piece of candy to save it.</p><p>Candies can be small, medium, or large.</p><p>Remember their <strong>SIZE</strong>.</p><p>Then, SORT the candies from smallest to biggest. <br>All the smalls in order, then all the mediums, then all the larges.</p>",
        playButton: 'Let\'s go!'
    },
    rounds: {
        end: "End of Round {{round}}",
        playNext: "Play next round!",
        all: "Excellent! You got {{correct}} trials correct!",
        some: "Great job! You got {{correct}} trials correct in this round.",
        none: "Good try. Let's keep practicing!"
    },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    gameComplete: {
        playTitle: "Game Completed!",
        playInstructions: " ",
        playButton: 'Back'
    },
    instructions: {
        size: [
            {
                'title': 'Save the Candy!',
                'body': 'The candy sorter is trashing our sweets!<br>\n' +
                    'Click on each piece of candy to save it.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Remember the Candy!',
                'body': 'Candies can be small, medium, or large.<br>\n' +
                    'Remember their SIZE.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sort the Candy!',
                'body': 'Then, SORT the candies from smallest to biggest.<br>\n' +
                    'All the smalls in order, then all of the mediums, then all of the larges.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Ready to start?',
                'body': 'Press yes to start, no to see the instructions again.',
                'buttonText': 'yes',
                'buttonText2': 'no',
                'buttonAction': 'goToPlay',
                'buttonAction2': 'goToBeginning'
            }

        ],
        color: [
            {
                'title': 'Save the Candy!',
                'body': 'The candy sorter is trashing our sweets!<br>\n' +
                    'Click on each piece of candy to save it.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Remember the Candy!',
                'body': 'Candies will be different colors.<br>\n' +
                    'Remember their COLOR.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sort the Candy!',
                'body': 'Then, SORT the candies by the Color Order at the top of your screen.<br>\n' +
                    'All the first color in order, then all of the second color in order, and so on.',
                'buttonText': 'Sweet!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Ready to start?',
                'body': 'Press yes to start, no to see the instructions again.',
                'buttonText': 'yes',
                'buttonText2': 'no',
                'buttonAction': 'goToPlay',
                'buttonAction2': 'goToBeginning'
            }

        ]
    },
    answerFeedback: {
        size: {
            correct: "Sweet! You got it right!",
            incorrect: "Watch the order and size of the sweets!"
        },
        color: {
            correct: "Sweet! You got it right!",
            incorrect: "Watch the order and color of the sweets!"
        }
    }
};
